import React, {Component} from 'react';
import Aux from '../../hoc/Aux/Aux';
import ProjectPlaceholder from '../../components/UI/Placeholder/ProjectPlaceholder/ProjectPlaceholder'
import Gallery from '../../components/Gallery/Gallery';
import GalleryItem from '../../components/Gallery/GalleryItem/GalleryItem';
import ProjectLogo from '../../components/ProjectLogo/ProjectLogo';
import ShareButton from '../../components/UI/SocialShareButton/SocialShareButton';
import ProjectLogoPlaceholder from '../../components/UI/Placeholder/ProjectLogoPlaceholder/ProjectLogoPlaceholder';
import {checkValidity} from '../../shared/utility';
import Input from '../../components/UI/Input/Input';

import ReCaptcha from 'reaptcha';
import Fingerprint2 from 'fingerprintjs2';
import {Button, Row, Col, FormText} from 'reactstrap';
import axios from '../../axios-base';
import moment from 'moment';
import queryString from 'qs';

class Project extends Component {
    constructor(props) {
        super(props);
        this.captcha = null;
        this.onGoToRequestPageHandler = this.onGoToRequestPageHandler.bind(this);
    }

    state = {
        loadedPost: null,
        fingerprint: '',
        responseToken: '',
        votingButton: 'Initialisiere...',
        votingReady: false,
        SMSCodeConfig: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                inputMode: "text",
                placeholder: 'Beispiel: PKX8QKZ8',
                label: 'Ihr SMS-Code zur Stimmabgabe',
                columns: 12,
                disabled: false
            },
            value: '',
            validation: {
                required: true,
                isSMSCode: true,
                maxLength: 8,
                minLength: 8
            },
            valid: false,
            errorMessage: 'Bitte geben Sie eine gültige SMS Code an',
            touched: false,
            ifAuth: false
        },
        userInput: '',
        touched: false,
        revealForm: false
    };

    componentDidMount() {
        /**
         * Set browser fingerprint hash
         * options passes options to Fingerprint2 class
         * fingerprint stores hashed browser fingerprint
         */
        const options = {};
        let fingerprint;

        // Todo: Move browser fingerprint getter before or after reCAPTCHA verification to only fingerprint voters
        Fingerprint2.getPromise(options)
            .then((components) => {
                let values = components.map(function (component) {
                    return component.value
                });
                fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
                this.setState({fingerprint: fingerprint});
            })
            .then(() => {
                if (this.props.match.params.id) {
                    if (!this.state.loadedPost || (this.state.loadedPost && this.state.loadedPost.id !== this.props.match.params.id)) {
                        axios.get('/projects/' + this.props.match.params.id)
                            .then(response => {
                                this.setState({loadedPost: response.data});
                            })
                            .catch(error => {
                                if (error.response) {
                                    // The request was made and the server responded with a status code
                                    // that falls out of the range of 2xx
                                    console.log(error.response.data);
                                    console.log(error.response.status);
                                    console.log(error.response.headers);
                                } else if (error.request) {
                                    // The request was made but no response was received
                                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                    // http.ClientRequest in node.js
                                    console.log(error.request);
                                } else {
                                    // Something happened in setting up the request that triggered an Error
                                    console.log('Error', error.message);
                                }
                                console.log(error.config);
                            });

                        // Test for query parameter and send PATCH request to this resource if param is present
                        const parsed = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true});

                        if (this.props.location.search && parsed.hasOwnProperty('token')) {
                            axios.patch('/projects/' + this.props.match.params.id + '/finale' + this.props.location.search, {
                                'fingerprint': this.state.fingerprint
                            })
                                .then(response => {
                                    this.props.history.push('/stimme-bestaetigt');
                                })
                                .catch(error => {
                                    if (error.response) {
                                        // The request was made and the server responded with a status code
                                        // that falls out of the range of 2xx
                                        console.log(error.response.data);
                                        console.log(error.response.status);
                                        console.log(error.response.headers);
                                    } else if (error.request) {
                                        // The request was made but no response was received
                                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                        // http.ClientRequest in node.js
                                        console.log(error.request);
                                    } else {
                                        // Something happened in setting up the request that triggered an Error
                                        console.log('Error', error.message);
                                    }
                                    console.log(error.config);
                                });
                        }
                    }
                }
            })
    }

    onGoToRequestPageHandler = () => {
        this.props.history.push('/code-anfordern/');
    };

    onChangeHandler(event){
        const userInput = event.target.value.toUpperCase();

        const updatedSMSCode = {
            ...this.state.SMSCodeConfig
        };

        updatedSMSCode.value = userInput;
        updatedSMSCode.valid = checkValidity(updatedSMSCode.value, updatedSMSCode.validation)
        updatedSMSCode.touched = true;

        let votingReady = true;
        votingReady = updatedSMSCode.valid && votingReady;

        this.setState({
            SMSCodeConfig: updatedSMSCode,
            votingReady: votingReady
        });
    }

    onVoteHandler = () => {
        this.captcha.execute();
    };

    // On reCAPTCHA verify
    onVerify = (responseToken) => {

        if (this.props.votingPhases.voteStarted && !this.props.votingPhases.voteStartedFinale) {
            axios.patch('/projects/' + this.props.match.params.id + '/vote', {
                'fingerprint': this.state.fingerprint,
                'responseToken': responseToken
            })
                .then((response) => {
                    if (response !== undefined) {
                        let updatedPost = {...this.state.loadedPost};
                        updatedPost.voteCount = response.data.voteCount;

                        this.setState({
                            loadedPost: updatedPost,
                            votingReady: false,
                            votingButton: 'Vielen Dank für Ihre Stimme!'
                        });
                        this.props.history.push('/stimme-bestaetigt');
                    } else {
                        this.setState({
                            votingReady: false,
                            votingButton: 'Sie haben bereits abgestimmt!'
                        });
                        throw new Error('Sie haben bereits abgestimmt!');
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            axios.patch('/sms', {
                // 'fingerprint': this.state.fingerprint,
                'responseToken': responseToken,
                'code': this.state.SMSCodeConfig.value,
                'projectId': this.props.match.params.id
            })
                .then((response) => {
                    if (response !== undefined) {

                        const updatedPost = {...this.state.loadedPost};
                        updatedPost.voteCountFinale += 1;

                        const updatedSMSCode = {...this.state.SMSCodeConfig};
                        updatedSMSCode.elementConfig.disabled = true;

                        this.setState({
                            votingReady: false,
                            votingButton: 'Sie haben bereits abgestimmt',
                            SMSCodeConfig: updatedSMSCode,
                            loadedPost: updatedPost
                        });
                        this.props.history.push('/stimme-bestaetigt');
                    } else {
                        this.setState({
                            votingReady: false,
                            votingButton: 'Sie haben bereits abgestimmt!'
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        this.captcha.reset();
    };

    onLoad = () => {
        let currentDate = this.props.currentDate;
        const startDate = this.props.votingPhases.voteStart;
        //const finaleStartDate = this.props.votingPhases.voteStartFinale;
        const hasFinale = this.props.votingPhases.hasFinale;

        if (!this.props.votingPhases.voteStarted) {
            //voting has not started
            this.setState({
                votingButton: 'Am ' + moment(startDate).format('DD.MM.') + ' geht\'s los', // The contest is starting on xx.yy.zzzz
                votingReady: false
            });
        } else if (!this.props.votingPhases.voteEnded) {
            //voting is ongoing
            if (hasFinale) {
                // if it has a finale, further checking
                if (this.props.votingPhases.voteStartedFinale) {
                    // the finale has started
                    if (this.state.loadedPost.isFinalist) {
                        //is the competitor a finalist
                        this.setState({
                            votingButton: 'Jetzt per SMS abstimmen!', // Vote by SMS
                            votingReady: false
                        });
                    } else {
                        //not a finalist, you cannot vote
                        this.setState({
                            votingButton: 'Leider nicht im Finale', // Not a finalist
                            votingReady: false
                        });
                    }
                } else {
                    // you can vote normally , not in finale yet/ without SMS
                    this.setState({
                        votingButton: 'Jetzt abstimmen!',
                        votingReady: true
                    });
                }
            } else {
                //no finale , vote normally
                this.setState({
                    votingButton: 'Jetzt abstimmen!',
                    votingReady: true
                });
            }
        } else {
            // the voting has ended
            this.setState({
                votingButton: 'Die Abstimmung ist beendet!',
                votingReady: false
            });
        }
    };

    onError = () => {
        console.log('Es ist ein Fehler beim Verifizieren des reCAPTCHAS aufgetreten!');
        this.setState({votingButton: 'Verifiziere reCAPTCHA...'});
    };

    render() {
        let projectVoteCount = '-';
        if (this.state.loadedPost) {
            //console.log('post geladen...');
            if (this.props.votingPhases.voteStartedFinale) {
                if (this.state.loadedPost.voteCountFinale && this.state.loadedPost.isFinalist) {
                    //console.log('vote count geladen...');
                    projectVoteCount = this.state.loadedPost.voteCountFinale;
                }
            } else {
                if (this.state.loadedPost.voteCount) {
                    //console.log('vote count geladen...');
                    projectVoteCount = this.state.loadedPost.voteCount;
                }
            }
        }

        let project = (
            <Row className="py-3">
                <Col xs="12">
                    <ProjectPlaceholder/>
                </Col>
            </Row>
        );

        let form = null;

        if (this.props.votingPhases.voteStartedFinale && !this.props.votingPhases.voteEnded) {
            form = (
                <Aux>
                    <Input
                        wrapperCSSClass="px-0 mb-0"
                        type={this.state.SMSCodeConfig.elementType}
                        name="SMSCode"
                        id="SMSCode"
                        placeholder={this.state.SMSCodeConfig.elementConfig.placeholder}
                        elementType={this.state.SMSCodeConfig.elementType}
                        elementConfig={this.state.SMSCodeConfig.elementConfig}
                        value={this.state.SMSCodeConfig.value}
                        changed={(event) => this.onChangeHandler(event)}
                        shouldValidate={this.state.SMSCodeConfig.validation}
                        touched={this.state.SMSCodeConfig.touched}
                        errorMessage={this.state.SMSCodeConfig.errorMessage}
                        invalid={!this.state.SMSCodeConfig.valid}
                        formText={this.state.SMSCodeConfig.formText} />
                    <FormText className="mb-3">Bitte Ihren 8-stelligen SMS-Code eingeben. <span style={{cursor: 'pointer'}} className="text-primary" role="button" onClick={this.onGoToRequestPageHandler}>Noch keinen Code?</span>
                    </FormText>
                </Aux>
            );
        }

        if (this.state.loadedPost) {

            project = (
                <Aux>
                    <Row className="my-3">
                        <Col xs="12" lg="5">
                            <Row className="py-3">
                                <Col>
                                    {this.state.loadedPost.projectLogo ?
                                        <ProjectLogo
                                            src={this.state.loadedPost.projectLogo}
                                            alt={this.state.loadedPost.title + ' Logo'}/>
                                        : <ProjectLogoPlaceholder/>}
                                </Col>
                                <Col className="d-flex flex-wrap align-self-center text-center">
                                    <h6 className="text-uppercase w-100">Stimmen</h6>
                                    <h1 className="w-100">{projectVoteCount}</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h1 className="text-primary">{this.state.loadedPost.contestantName}</h1>
                                    <h2 className="text-dark">{this.state.loadedPost.projectTitle}</h2>

                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="6" lg="12">
                                    <ReCaptcha
                                        ref={e => (this.captcha = e)}
                                        sitekey="6Lcm84MUAAAAAEYJu6Xnthn57W2wrIsJ1qu-UbTY"
                                        onVerify={this.onVerify}
                                        onError={this.onError}
                                        onLoad={this.onLoad}
                                        size="invisible"
                                    />
                                    {this.state.loadedPost.isFinalist && !this.props.votingPhases.voteEnded ? form : null}
                                    <Button
                                        block
                                        disabled={!this.state.votingReady}
                                        color="primary"
                                        onClick={this.onVoteHandler}
                                        >
                                        {this.state.votingButton}
                                    </Button>
                                    <p className="text-black-50 small my-2">Diese Seite wird durch reCAPTCHA geschützt.
                                    Es gelten die <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Daten&shy;schutz&shy;erklärung</a> und die <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Nutzungs&shy;bedingungen</a> von Google.</p>
                                </Col>
                                <Col xs="12" sm="6" lg="12" className="mb-2">
                                    <Row>
                                        <Col xs="6" lg="4" className="pb-1 pr-1 pl-3 pt-0 pl-md-1 pl-lg-3">
                                            <ShareButton shareType="facebook"/>
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pr-3 pl-1 pt-0 pr-md-1 pr-lg-3 d-md-none">
                                            <ShareButton
                                                shareTitle={this.state.loadedPost.contestantName}
                                                shareType="whatsApp" />
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pr-1 pl-3 pt-0 pl-md-1">
                                            <ShareButton
                                                shareTitle={this.state.loadedPost.contestantName}
                                                shareType="email"/>
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pr-3 pl-1 pt-0 pr-md-1 pr-lg-3">
                                            <ShareButton
                                                shareTitle={this.state.loadedPost.contestantName}
                                                shareType="twitter"/>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>

                        <Col xs="12" lg="7">
                            <Row>
                                <Col xs={{size: 12, order: 2}} lg={{order: 1}}>
                                    <h1 className="text-primary">Über uns:</h1>
                                    <p>{this.state.loadedPost.contestantAnswer1}</p>
                                </Col>

                                <Col xs={{size: 12, order: 3}} lg={{order: 2}}>
                                    <h1 className="text-primary">So sind wir für unseren Nachwuchs im Einsatz:</h1>
                                    <p>{this.state.loadedPost.contestantAnswer2}</p>
                                </Col>

                                <Col xs={{size: 12, order: 1}} lg={{order: 3}} className="px-0 p-md-3">
                                    <Gallery>
                                        {this.state.loadedPost.projectGallery.map((galleryItem, index) => (
                                            <GalleryItem
                                                key={index}
                                                src={galleryItem}
                                                alt={'Galeriebild ' + index}/>
                                        ))}
                                    </Gallery>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Aux>
            )
        }

        return (
            <Aux>
                {project}
            </Aux>
        );
    }
}

export default Project;
