import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Container, Row} from 'reactstrap';

import axios from './axios-base';
import Aux from './hoc/Aux/Aux';
import Home from './containers/Home/Home';
import SignUp from './containers/SignUp/SignUp';
import ProjectList from './containers/ProjectList/ProjectList';
import Project from './containers/Project/Project';
import Page from './components/Page/Page';
import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import withErrorHandler from './hoc/withErrorHandler/withErrorHandler';
import * as actions from './store/actions/index';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import moment from 'moment';
import ConsentModal from './components/UI/Modal/ConsentModal/ConsentModal';
import Cookies from 'js-cookie';
import {Helmet} from "react-helmet";
import {personalization, statisticTracking} from "./shared/trackingScripts";
import ScrollToTop from './shared/scrollToTop';
import PageNotFound from './components/Page/PageNotFound.js'
import SMSRequest from "./containers/SMSRequest/SMSRequest";

class App extends Component {
    // Todo: move countdown dates to redux store and GET via axios
    // Todo: Refactor state object to more sensible naming conventions
    constructor(props) {
        super(props);
        this.state = {
            pages: null,
            votingPhases: {
                voteStart: '',
                voteStartFinale: '',
                voteEnd: '',
                voteStarted: false,
                voteStartedFinale: false,
                hasFinale: false,
                voteEnded: false
            },
            currentDate: moment(),
            pageNotFound: false
        };
        this.pageNotFoundCallback = this.pageNotFoundCallback.bind(this);
    }

    pageNotFoundCallback = (pageState) => {
        this.setState({pageNotFound: pageState});
    };

    // Try to automatically sign in
    componentDidMount() {
        this.props.onTryAutoSignup();

        axios.get('/phases/')
            .then(response => {
                this.setState({votingPhases: response.data});
            })
            .catch(error => {
                this.setState({error: true});
            });

        axios.get('/pages/')
            .then(response => {
                if (process.env.REACT_APP_BUILD_FOR_ARCHIVAL === "true") {
                    response.data = response.data.filter((item) => item.linkTarget !== "/bewerbung");
                }
                this.setState({pages: response.data});
            })
            .catch(error => {
                this.setState({error: true});
            });

        axios.get('/menus/')
            .then(response => {
                const primaryNavigation = response.data.filter(menuItem => { return menuItem.position === 'primary' && menuItem.highlight === false})
                const primaryNavigationCTA = response.data.filter(menuItem => { return menuItem.position === 'primary' && menuItem.highlight === true})
                const secondaryNavigation = response.data.filter(menuItem => { return menuItem.position === 'secondary' })
                this.setState({
                    primaryNavigation: primaryNavigation,
                    primaryNavigationCTA: primaryNavigationCTA,
                    secondaryNavigation: secondaryNavigation
                });
            })
            .catch(error => {
                this.setState({error: true});
            });
    }

    render() {

        let dynamicPages = null;
        let dynamicHeader = null;
        let consentManagementModal = null;

        // Check if cookies exist and display consent management modal OR load tracking scripts
        if (Cookies.get('bigfoot_personalization-consent') && Cookies.get('bigfoot_statistic-consent')) {
            dynamicHeader = <Helmet>{personalization()}{statisticTracking()}</Helmet>
        } else if (Cookies.get('bigfoot_statistic-consent')) {
            dynamicHeader = <Helmet>{statisticTracking()}</Helmet>
        } else if (Cookies.get('bigfoot_personalization-consent')) {
            dynamicHeader = <Helmet>{personalization()}</Helmet>
        }

        if (!Cookies.get('bigfoot_cookie-consent')) { consentManagementModal = <ConsentModal pages={this.state.pages}/> }

        if (this.state.pages) {
            dynamicPages = (
                this.state.pages.map((page, i) => {
                    return (
                        /* The following component is monitored by Font Awesome dom.watch(). Check children */
                        <Route
                            key={i}
                            exact //Caution: This may redirect to 404 pag if embedded links are opened from the page.
                            path={`/${page.slug}`}
                            render={(props) => <Row id="dom-watch"><Page pageTitle={page.title} pageBody={page.body} {...props}/></Row>}
                        />
                    )
                })
            )
        }


        return (
            <Aux>
                <Container>
                    {dynamicHeader}
                    {consentManagementModal}
                    <Header
                        votingPhases={this.state.votingPhases}
                        primaryNavigation={this.state.primaryNavigation}
                        primaryNavigationCTA={this.state.primaryNavigationCTA}
                        pageNotFound={this.state.pageNotFound} />
                    <ScrollToTop />
                    <Switch>
                            {dynamicPages}
                        <Route
                            path="/projekte/:id"
                            render={(props) => <Project votingPhases={this.state.votingPhases} currentDate={this.state.currentDate} {...props} />} />
                        <Route path="/code-anfordern"
                               render={(props) => <SMSRequest votingPhases={this.state.votingPhases} {...props} />} />
                        <Route path="/projekte" render={(props) => <ProjectList votingPhases={this.state.votingPhases} currentDate={this.state.currentDate} {...props} />} />
                        <Route path="/bewerbung" render={(props) => <SignUp {...props} />} />
                        <Route path="/logout" component={Logout}/>
                        <Route path="/login" component={Auth}/>
                        <Route path="/" exact render={(props) => <Home votingPhases={this.state.votingPhases} {...props} />} />
                        <Route path="*" status={404} render={() => <PageNotFound pageNotFoundCallback={this.pageNotFoundCallback} />} />
                    </Switch>
                    <Footer secondaryNavigation={this.state.secondaryNavigation} />
                </Container>
            </Aux>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
};

export default withRouter(connect(null, mapDispatchToProps)(withErrorHandler(App, axios)));
